import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import React, { useEffect, useState } from 'react';
import { Control, Controller, FieldValues, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { fetchDataById, updateData, uploadOverviewImage } from '../../services/firebaseService';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { resetExternalImageEntityState, resetInternalImageEntityState, resetState } from '../../store/fileUploadSlice';
import { uploadFileWithProgress } from '../../store/fileUploadThunks';
import { Box, LinearProgress, Typography } from '@mui/material';
import { uploadMultipleFilesWithProgress } from '../../store/multipleFilesUploadThunk';
import { uploadEditFileWithProgress } from '../../store/EditFileUploadThunk';
import { projectSchema } from '../../shared/schemas';

type FormData = {
  id: string;
  initiatorName: string;
  city: string;
  description: string;
  sectorName: string;
  startDate: string;
  productionCapacity: number;
  employeesNo: number;
  projectArea: number;
  projectStatus: string;
  location: {
    city: string;
    id: string;
    lat: string;
    lng: string;
  };

  // externalImageEntity: FileList;
  // internalImageEntity: FileList;
  externalImageEntity: { id: number; path: string }[];
  internalImageEntity: { id: number; path: string }[];
  formImage: string;
  // formImage: FileList | null;
  // externalImageEntity: string[] | null;
  // internalImageEntity: string[] | null;
  // videos: FileList;
};

type InputNormalProps = {
  label: string;
  type: string;
  name: any;
  defaultValue?: any;
  control: Control<FormData, any>;
  error: string | undefined;
  autoFocus?: boolean;
};
const inputClasses = `outline-none py-1.5 px-2 rounded-md border focus:border-blue-200 w-full`;
const InputNormal = ({ label, name, defaultValue, control, type, autoFocus = false, error }: InputNormalProps) => {
  return (
    <div className="flex flex-col space-y-1.5 w-full">
      <label htmlFor={name} className="font-medium">
        {label}
      </label>
      <div>
        <Controller
          name={name}
          defaultValue={defaultValue || ''}
          control={control}
          render={({ field }) => (
            <input {...field} type={type} className={inputClasses} autoFocus={autoFocus} id={name} />
          )}
        />
      </div>
      {error && <span className="text-center text-red-400">{error}</span>}
    </div>
  );
};

const EditProjectDetails = () => {
  const {
    progress,
    url,
    error,
    externalImageEntityProgress,
    externalImageEntityUrl,
    externalImageEntityError,
    internalImageEntityProgress,
    internalImageEntityUrl,
    internalImageEntityError,
  } = useSelector((state: RootState) => state.fileUpload);

  const dispatch: ThunkDispatch<RootState, unknown, AnyAction> = useDispatch();

  const sectorPaths: string[] = [
    'الفاكهة',
    'الورد',
    'البن',
    'العسل',
    'المحاصيل البعلية',
    'الثروة الحيوانية',
    'القيمة المضافة',
    'السمك',
  ];

  const cities: string[] = [
    'الباحة',
    'القصيم',
    'حائل',
    'عسير',
    'الشرقية',
    'جازان',
    'الرياض',
    'الطائف',
    'المدينة المنورة',
    'الجبيل',
    'حفر الباطن',
    'عرعر',
    'القنفذة',
    'شقيق',
    'أملج',
    'خور فرسان',
    'الرايس',
    'القطيف',
    'تبوك',
    'نجران',
    'فيفا (جازان)',
    'الداير (جازان)',
    'مكة المكرمة',
    'الجوف',
  ];
  const cityCoordinates = {
    الباحة: { city: 'الباحة', lat: '20.01296', lng: '41.46871' },
    القصيم: { city: 'القصيم', lat: '26.35131', lng: '44.110618' },
    حائل: { city: 'حائل', lat: '27.512377', lng: '41.727032' },
    عسير: { city: 'عسير', lat: '18.2962', lng: '42.7287' },
    الشرقية: { city: 'الشرقية', lat: '24.023498', lng: '50.12086' },
    جازان: { city: 'جازان', lat: '16.89054996', lng: '42.57440605' },
    الرياض: { city: 'الرياض', lat: '24.712357', lng: '46.670149' },
    الطائف: { city: 'الطائف', lat: '21.282786', lng: '40.420764' },
    'المدينة المنورة': { city: 'المدينة المنورة', lat: '24.39073', lng: '39.49886' },
    الجبيل: { city: 'الجبيل', lat: '24.901182', lng: '46.449338' },
    'حفر الباطن': { city: 'حفر الباطن', lat: '28.3789', lng: '45.9753' },
    عرعر: { city: 'عرعر', lat: '30.960115', lng: '41.0597319' },
    القنفذة: { city: 'القنفذة', lat: '19.12926', lng: '41.07925' },
    شقيق: { city: 'شقيق', lat: '17.71962', lng: '42.02879' },
    أملج: { city: 'أملج', lat: '25.04974', lng: '37.26468' },
    'خور فرسان': { city: 'خور فرسان', lat: '16.71232', lng: '42.18577' },
    الرايس: { city: 'الرايس', lat: '23.57557', lng: '38.60575' },
    القطيف: { city: 'القطيف', lat: '26.57438', lng: '50.00129' },
    تبوك: { city: 'تبوك', lat: '28.3856872', lng: '36.5707123' },
    نجران: { city: 'نجران', lat: '17.56519', lng: '44.22925' },
    'فيفا (جازان)': { city: 'فيفا (جازان)', lat: '17.24741', lng: '43.10759' },
    'الداير (جازان)': { city: 'الداير (جازان)', lat: '16.8896', lng: '42.5539' },
  };
  // const cities: string[] = [
  //   'الباحة',
  //   'القصيم',
  //   'حائل',
  //   'عسير',
  //   'الشرقية',
  //   'جازان',
  //   'الرياض',
  //   'الطائف',
  //   'المدينة المنورة',
  //   'الجبيل',
  //   'حفر الباطن',
  //   'عرعر',
  //   'القنفذة',
  //   'شقيق',
  //   'أملج',
  //   'خور فرسان',
  //   'الرايس',
  //   'القطيف',
  //   'تبوك',
  //   'نجران',
  //   'فيفا (جازان)',
  //   'الداير (جازان)',
  //   'مناطق متعدده',
  // ];

  const projectStatuses: string[] = ['منتظم', 'متأخر'];

  const formatDate = (dateString: any) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };
  const [projectData, setProjectData] = useState<FormData>();
  const [loading, setLoading] = useState(true);
  const [newExternalImages, setNewExternalImages] = useState<{ id: number; path: string }[]>();
  const [newInternalImages, setNewInternalImages] = useState<{ id: number; path: string }[]>();
  const [newOverviewImage, setNewOverviewImages] = useState<string>();
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    watch,
  } = useForm<FormData>({
    resolver: joiResolver(projectSchema),
  });

  const { sectorName, id } = useParams();

  useEffect(() => {
    fetchDataById(`projects/${id}`, (data) => {
      setProjectData(data);
    });
    setLoading(false);
  }, [id, sectorName]);

  useEffect(() => {
    if (url) {
      setValue('formImage', url);
      setNewOverviewImages(url || '');
    }
  }, [url]);
  useEffect(() => {
    // Cleanup state when the component unmounts
    return () => {
      dispatch(resetState());
    };
  }, [dispatch]);

  const uploadNewFile = (newFile: any) => {
    if (newFile) {
      dispatch(uploadFileWithProgress({ newFile }));
    }
  };

  const handleOverviewChange = async (fieldName: keyof FormData, e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    if (file) {
      uploadNewFile(file);
    }
  };

  useEffect(() => {
    if (externalImageEntityUrl) {
      setNewExternalImages(externalImageEntityUrl);
      setValue('externalImageEntity', externalImageEntityUrl);
    }
  }, [externalImageEntityUrl]);
  useEffect(() => {
    if (internalImageEntityUrl) {
      setNewInternalImages(internalImageEntityUrl);
      setValue('internalImageEntity', internalImageEntityUrl);
    }
  }, [internalImageEntityUrl]);

  useEffect(() => {
    // Cleanup state when the component unmounts
    return () => {
      dispatch(resetExternalImageEntityState());
      dispatch(resetInternalImageEntityState());
    };
  }, [dispatch]);

  const uploadNewMultipleFiles = (
    newFiles: any,
    imgType: string,
    images: { id: number; path: string }[],
    imageId: number,
  ) => {
    if (newFiles) {
      dispatch(
        uploadEditFileWithProgress({
          newFile: newFiles,
          edit: true,
          imgType: imgType,
          images: images,
          imageId: imageId,
        }),
      );
    }
  };
  const uploadNewAddedMultipleFiles = (newFiles: any, imgType: string) => {
    if (newFiles) {
      if (imgType === 'external') {
        dispatch(uploadMultipleFilesWithProgress({ newFiles: newFiles, imgType: imgType }));
      } else {
        dispatch(uploadMultipleFilesWithProgress({ newFiles: newFiles, imgType: imgType }));
      }
    }
  };
  const handleUnfoundFileChange = async (fieldName: keyof FormData, e: React.ChangeEvent<HTMLInputElement>) => {
    const filesBeforeUpload = e.target.files;

    if (filesBeforeUpload) {
      if (fieldName === 'externalImageEntity') {
        // setNewExternalFile(filesBeforeUpload);
        uploadNewAddedMultipleFiles(filesBeforeUpload, 'external');
      } else {
        // setNewInternalFile(filesBeforeUpload);
        uploadNewAddedMultipleFiles(filesBeforeUpload, 'internal');
      }
    }
  };
  // Handle file input changes
  const handleFileChange = async (
    fieldName: keyof FormData,
    imageId: number,
    e: React.ChangeEvent<HTMLInputElement>,
    images: { id: number; path: string }[],
  ) => {
    const file = e.target.files && e.target.files[0];
    if (file) {
      if (fieldName === 'externalImageEntity') {
        uploadNewMultipleFiles(file, 'external', images, imageId);
      } else {
        uploadNewMultipleFiles(file, 'internal', images, imageId);
      }
    }
  };

  const selectedCity = watch('city');
  const [isLngModified, setIsLngModified] = useState(false);
  const [isLatModified, setIsLatModified] = useState(false);

  useEffect(() => {
    if (selectedCity && cityCoordinates[selectedCity as keyof typeof cityCoordinates]) {
      const { city, lat, lng } = cityCoordinates[selectedCity as keyof typeof cityCoordinates];

      // Only reset values if the user hasn't manually modified them
      if (isLatModified && projectData?.city !== watch('city')) {
        setValue('location.lat', lat); // Set default latitude
      }
      if (isLngModified && projectData?.city !== watch('city')) {
        setValue('location.lng', lng); // Set default longitude
      }
      setValue('location.city', city); // Set default city
    }
  }, [selectedCity, isLatModified, isLngModified, setValue]);

  useEffect(() => {
    if (selectedCity !== projectData?.city) {
      setIsLngModified(true);
      setIsLatModified(true);
    }
  }, [selectedCity]);

  const handleLngChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsLngModified(true); // Mark longitude as modified
    setValue('location.lng', e.target.value);
  };

  const handleLatChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsLatModified(true); // Mark latitude as modified
    setValue('location.lat', e.target.value);
  };

  const navigate = useNavigate();

  const onSubmit = (data: FieldValues) => {
    data = {
      ...data,
      projectStatus: data.projectStatus || null,
      startDate: data.startDate || null,
      externalImageEntity: data.externalImageEntity || null,
      internalImageEntity: data.internalImageEntity || null,
    };

    updateData(`projects/${id}`, data);

    navigate(`/`);

    // You can send the data to your backend or perform other actions here
  };

  const imagesUpdateViewer = (images: { id: number; path: string }[], type: string) => {
    const imageType = type === 'externalImageEntity' ? 'externalImageEntity' : 'internalImageEntity';

    return (
      <div className="grid grid-cols-3 grid-rows-1 gap-4 my-5 rounded place-content-center place-items-center">
        {images ? (
          images.map((image, index) => (
            <div className="flex items-center justify-between col-span-1 row-span-1" key={index}>
              <label>
                <img className=" cursor-pointer h-[170px]" src={image.path} alt={`${type}`} />
                <input
                  style={{ display: 'none' }}
                  type="file"
                  onChange={(e) => handleFileChange(imageType, image.id, e, images)}
                />
              </label>
            </div>
          ))
        ) : (
          <div className="w-full col-span-3">
            {/* <label className="font-medium">الصور الخارجية</label> */}
            <Controller
              name="externalImageEntity"
              control={control}
              render={({ field, fieldState }) => (
                <input
                  type="file"
                  onChange={(e) => handleUnfoundFileChange(imageType, e)}
                  accept="image/*"
                  multiple
                  className={inputClasses}
                />
              )}
            />
          </div>
        )}
      </div>
    );
  };

  if (loading || !projectData) return <p>Loading...</p>;

  console.log(projectData);
  return (
    <section className="p-10">
      <header>
        <h3 className="text-2xl font-bold text-center">
            تعديل مشروع: <span className="text-gray-500 "> {projectData?.initiatorName} </span>
        </h3>
        <h3 className="text-2xl font-bold text-center">
          من قطاع: <span className="text-gray-500 ">{projectData?.sectorName}</span>
        </h3>
      </header>
      <form
        onSubmit={handleSubmit((data) => {
          onSubmit(data);
          reset();
        })}
        encType="multipart/form-data">
        <InputNormal
          label="اسم المشروع"
          name="initiatorName"
          defaultValue={projectData?.initiatorName}
          control={control}
          type="text"
          error={errors.initiatorName?.message}
        />
        <div className="flex flex-col space-y-1.5 w-full">
          <label htmlFor="city" className="font-medium">
            اسم المدينة
          </label>
          <div>
            <Controller
              name="city"
              defaultValue={projectData?.city}
              control={control}
              render={({ field }) => (
                <select {...field} className={`${inputClasses}`} id="city">
                  <option value="" disabled={watch('city') ? true : false} className="text-white">
                    اختر
                  </option>
                  {cities.map((city, index) => (
                    <option key={index} value={city}>
                      {city}
                    </option>
                  ))}
                </select>
              )}
            />
          </div>
          {errors.city?.message && <span className="text-center text-red-400">{errors.city?.message}</span>}
        </div>

        <>
          <div className="flex flex-col space-y-1.5 w-full">
            <label htmlFor="location.lng" className="font-medium">
              خطوط الطول
            </label>
            <div>
              <Controller
                name="location.lng"
                defaultValue={isLngModified ? watch('location.lng') : projectData?.location?.lng}
                control={control}
                render={({ field }) => (
                  <input
                    {...field}
                    type="text"
                    className={inputClasses}
                    id="location.lng"
                    name='"location.lng"'
                    onChange={(e: any) => {
                      handleLngChange(e);
                      field.onChange(e); // Ensure form state updates
                    }}
                  />
                )}
              />
            </div>
            {errors?.location?.lng?.message && (
              <span className="text-center text-red-400">{errors?.location?.lng?.message}</span>
            )}{' '}
          </div>
          <div className="flex flex-col space-y-1.5 w-full">
            <label htmlFor="location.lat" className="font-medium">
              دوائر العرض
            </label>
            <div>
              <Controller
                name="location.lat"
                defaultValue={isLatModified ? watch('location.lat') : projectData?.location?.lat}
                control={control}
                render={({ field }) => (
                  <input
                    {...field}
                    type="text"
                    className={inputClasses}
                    id="location.lat"
                    name='"location.lat"'
                    onChange={(e: any) => {
                      handleLatChange(e);
                      field.onChange(e); // Ensure form state updates
                    }}
                  />
                )}
              />
            </div>
            {errors?.location?.lat?.message && (
              <span className="text-center text-red-400">{errors?.location?.lat?.message}</span>
            )}{' '}
          </div>
        </>
        {/* <InputNormal
          label="المدينة"
          name="city"
          defaultValue={projectData?.city}
          control={control}
          type="text"
          error={errors.city?.message}
        /> */}
        <InputNormal
          label="وصف المشروع"
          name="description"
          defaultValue={projectData?.description}
          control={control}
          type="text"
          error={errors.description?.message}
        />
        <div className="flex flex-col space-y-1.5 w-full">
          <label htmlFor="sectorName" className="font-medium">
            اسم القطاع
          </label>
          <div>
            <Controller
              name="sectorName"
              defaultValue={projectData?.sectorName}
              control={control}
              render={({ field }) => (
                <select {...field} className={`${inputClasses}`} id="sectorName">
                  {sectorPaths.map((sector, index) => (
                    <option key={index} value={sector}>
                      {sector}
                    </option>
                  ))}
                </select>
              )}
            />
          </div>
          {errors.sectorName?.message && <span className="text-center text-red-400">{errors.sectorName?.message}</span>}
        </div>

        <div className="flex flex-col space-y-1.5 w-full">
          <label htmlFor="sectorName" className="font-medium">
            بداية التشغيل
          </label>
          <div>
            <Controller
              name="startDate"
              control={control}
              defaultValue={formatDate(projectData?.startDate)}
              render={({ field }) => (
                <input
                  type="date"
                  {...field}
                  className={`${inputClasses}`}
                  id="startDate"
                  placeholder={projectData?.startDate}
                />
              )}
            />
          </div>
          {errors.startDate?.message && <span className="text-center text-red-400">{errors.startDate?.message}</span>}{' '}
        </div>

        <InputNormal
          label="الطاقة الانتاجية"
          name="productionCapacity"
          defaultValue={projectData?.productionCapacity}
          control={control}
          type="number"
          error={errors.productionCapacity?.message}
        />

        <InputNormal
          label="عدد العمالة"
          name="employeesNo"
          defaultValue={projectData?.employeesNo}
          control={control}
          type="number"
          error={errors.employeesNo?.message}
        />

        <InputNormal
          label="مساحة المشروع"
          name="projectArea"
          defaultValue={projectData?.projectArea}
          control={control}
          type="number"
          error={errors.projectArea?.message}
        />

        <div className="flex flex-col space-y-1.5 w-full">
          <label htmlFor="projectStatus" className="font-medium">
            حالة المشروع
          </label>
          <div>
            <Controller
              name="projectStatus"
              defaultValue={projectData?.projectStatus}
              control={control}
              render={({ field }) => (
                <select {...field} className={`${inputClasses}`} id="projectStatus">
                  {!projectData?.projectStatus && (
                    <option value="" disabled={watch('projectStatus') ? true : false} className="text-white">
                      اختر
                    </option>
                  )}
                  {projectStatuses.map((status, index) => (
                    <option key={index} value={status}>
                      {status}
                    </option>
                  ))}
                </select>
              )}
            />
          </div>
          {errors.projectStatus?.message && (
            <span className="text-center text-red-400">{errors.projectStatus?.message}</span>
          )}
        </div>

        {/* <InputNormal
          label="القطاع"
          name="sectorName"
          defaultValue={projectData?.sectorName}
          control={control}
          type="text"
          error={errors.sectorName?.message}
        /> */}
        {/* <InputNormal
              label="الموقع الجغرافي"
              name="location.map"
          defaultValue={projectData?.location?.map}
              control={control}
              type="text"
              error={errors.location?.map?.message}
            /> */}

        {/* <InputNormal
          label="المدينة"
          name="location.city"
          defaultValue={projectData?.location?.city}
          control={control}
          type="text"
          error={errors.location?.city?.message}
        />
        <InputNormal
          label="خطوط الطول"
          name="location.lat"
          defaultValue={projectData?.location?.lat}
          control={control}
          type="text"
          error={errors.location?.lat?.message}
        />
        <InputNormal
          label="دوائر العرض"
          name="location.lng"
          defaultValue={projectData?.location?.lng}
          control={control}
          type="text"
          error={errors.location?.lng?.message}
        /> */}

        <div className="my-5 text-center">
          <label className="font-medium">الصور الخارجية</label>

          {projectData && (
            <Controller
              name="externalImageEntity"
              control={control}
              defaultValue={projectData?.externalImageEntity}
              render={({ field, fieldState }) =>
                imagesUpdateViewer(
                  newExternalImages ? newExternalImages : projectData?.externalImageEntity,
                  'externalImageEntity',
                )
              }
            />
          )}
          {externalImageEntityProgress > 0 && (
            <div className="flex items-center justify-center">
              <div className="w-[50%] mr-1">
                <LinearProgress variant="determinate" value={externalImageEntityProgress} />
              </div>
              <Box sx={{ minWidth: 35 }}>
                <Typography variant="body2" color="text.secondary">{`${Math.round(
                  externalImageEntityProgress,
                )}%`}</Typography>
              </Box>
            </div>
          )}
          {errors.externalImageEntity && externalImageEntityProgress !== 100 && (
            <span className="text-center text-red-400">{errors.externalImageEntity?.message}</span>
          )}
        </div>
        <hr />
        <div className="my-5 text-center">
          <label className="font-medium">الصور الداخلية</label>

          {projectData && (
            <Controller
              name="internalImageEntity"
              control={control}
              defaultValue={projectData?.internalImageEntity}
              render={({ field, fieldState }) =>
                imagesUpdateViewer(
                  newInternalImages ? newInternalImages : projectData?.internalImageEntity,
                  'internalImageEntity',
                )
              }
            />
          )}
          {internalImageEntityProgress > 0 && (
            <div className="flex items-center justify-center">
              <div className="w-[50%] mr-1">
                <LinearProgress variant="determinate" value={internalImageEntityProgress} />
              </div>
              <Box sx={{ minWidth: 35 }}>
                <Typography variant="body2" color="text.secondary">{`${Math.round(
                  internalImageEntityProgress,
                )}%`}</Typography>
              </Box>
            </div>
          )}

          {errors.internalImageEntity && internalImageEntityProgress !== 100 && (
            <span className="text-center text-red-400">{errors.internalImageEntity?.message}</span>
          )}
        </div>
        <hr />

        <div className="my-5 text-center">
          <label className="font-medium">صورة نموذج المشروع</label>
          <div>
            <div className="flex items-center justify-center">
              <Controller
                name="formImage"
                control={control}
                defaultValue={projectData?.formImage}
                render={({ field, fieldState }) => (
                  <label>
                    <img
                      className="mt-4 cursor-pointer h-[170px]"
                      src={newOverviewImage ? newOverviewImage : projectData?.formImage}
                      alt="overview image"
                    />
                    <input
                      style={{ display: 'none' }}
                      type="file"
                      onChange={(e) => handleOverviewChange('formImage', e)}
                    />
                  </label>
                )}
              />
            </div>
            {progress > 0 && (
              <div className="flex items-center justify-center">
                <div className="w-[50%] mr-1">
                  <LinearProgress variant="determinate" value={progress} />
                </div>
                <Box sx={{ minWidth: 35 }}>
                  <Typography variant="body2" color="text.secondary">{`${Math.round(progress)}%`}</Typography>
                </Box>
              </div>
            )}
            {errors.formImage && <span className="text-center text-red-400">{errors.formImage?.message}</span>}
          </div>
          {/* <Controller
            defaultValue={projectData?.formImage}
            name="formImage"
            control={control}
            render={({ field, fieldState }) => (
              <input
                type="file"
                // onChange={(e) => handleFileChange('formImage', e)} accept="image/*"
              />
            )}
          /> */}
          {errors.formImage && <span>{errors.formImage.message}</span>}
        </div>

        {/* <div>
          <label>Videos</label>
          <Controller
            name="videos"
            control={control}
            render={({ field }) => (
              <input type="file" onChange={(e) => handleFileChange('videos', e)} accept="video/*" multiple />
            )}
          />
        </div> */}
        <button className="outline-none border px-3 py-1.5 rounded-md mt-5" type="submit">
          Submit
        </button>
      </form>
    </section>
  );
};

export default EditProjectDetails;
